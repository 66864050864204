import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '../../../actions/filtersActions'
import { CustomTextField } from '../../general/CustomTextField'

const Filters = () => {
  const dispatch = useDispatch()
  const { q, visible } = useSelector((state) => state.filters)

  const handleFilterChange = (field, value) => {
    dispatch(setFilter(field, value))
  }

  if (!visible) return null

  return (
    <div className="filters">
      <div className="row filter-section">
        <div className="full-field">
          <div className="field-label">Search</div>
          <CustomTextField name="q" value={q} onChange={(_, value) => handleFilterChange('q', value)} width="full" />
        </div>
      </div>
    </div>
  )
}

export default React.memo(Filters)
