import React, { useCallback, useEffect, useMemo } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { useTheme } from '@mui/material'
import { push } from 'connected-react-router'
import * as router from '../../../services/router'
import { exhibitionsOrder, loadExhibitionsBasic } from '../../../actions/exhibitionsActions'
import { loadPersons } from '../../../actions/personsActions'
import { TopSearch } from '../../header/TopSearch'
import { dateByAccuracy, sortByTextOrDate } from '../../../utils/utils'
import Filters from './Filters'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import { Add } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import TextButton from '../../general/TextButton'
import { filtersReset } from '../../../actions/filtersActions'

const headers = [
  { field: 'title', title: 'Title' },
  { field: 'gallery_name', title: 'Institution' },
  { field: 'from_year', title: 'Date from' },
  { field: 'to_year', title: 'Date to' },
  { field: 'location', title: 'Location' },
  { field: 'currator', title: 'Curator' },
  // { field: 'artworks', title: 'Number of artworks' },
  { field: 'is_separate', title: 'Separate/Group' },
]

const ListTableHead = makeSortableTableHeader(headers)

const TableRows = React.memo(({ items, persons, goToUrl, orderBy, order }) => {
  const processedItems = useMemo(() => {
    return items.map((item) => ({
      ...item,
      is_separate: item.is_separate ? 'Separate' : 'Group',
      gallery_name: item.institution?.name || '',
      curator:
        item.currators?.[0] && persons[item.currators[0].id]
          ? `${persons[item.currators[0].id].firstname} ${persons[item.currators[0].id].lastname}`
          : '',
    }))
  }, [items, persons])

  const sortedItems = useMemo(() => sortByTextOrDate(processedItems, orderBy, order), [processedItems, orderBy, order])

  return sortedItems.map((item) => (
    <TableRow key={item.id} onClick={() => goToUrl(router.ROUTE_EXHIBITION_EDIT + item.id)}>
      <CustomTableCell>{item.title}</CustomTableCell>
      <CustomTableCell>{item.gallery_name}</CustomTableCell>
      <CustomTableCell>
        {dateByAccuracy(item.from_accuracy, item.from_year, item.from_month, item.from_day)}
      </CustomTableCell>
      <CustomTableCell>{dateByAccuracy(item.to_accuracy, item.to_year, item.to_month, item.to_day)}</CustomTableCell>
      <CustomTableCell>{item.location}</CustomTableCell>
      <CustomTableCell>{item.currator}</CustomTableCell>
      {/* <CustomTableCell>{item.artworks.length}</CustomTableCell> */}
      <CustomTableCell>{item.is_separate}</CustomTableCell>
    </TableRow>
  ))
})

const ExhibitionsTable = React.memo(({ goToUrl }) => {
  const exhibitions = useSelector((state) => getExhibitions(state))
  const persons = useSelector((state) => state.persons.items)
  const { order, orderBy } = useSelector((state) => getOrder(state, 'exhibitions'))
  const dispatch = useDispatch()
  const orderFn = (field) => {
    const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc'
    dispatch(exhibitionsOrder(field, newOrder))
  }

  return (
    <Table className="non-fixed">
      <ListTableHead order={order} orderBy={orderBy} orderFn={orderFn} />
      <TableBody>
        <TableRows items={exhibitions} persons={persons} orderBy={orderBy} order={order} goToUrl={goToUrl} />
      </TableBody>
    </Table>
  )
})

const ExhibitionsPresenter = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const goToUrl = useCallback((url) => dispatch(push(url)), [dispatch])

  useEffect(() => {
    dispatch(filtersReset())
  }, [dispatch])

  return (
    <div className="exhibitions">
      <TopSearch title="Exhibitions" FiltersComponent={Filters}>
        <div className="item-right">
          <TextButton
            label="Add exhibition"
            color="primary"
            startIcon={<Add style={{ color: theme.palette.primary.main }} />}
            onClick={() => goToUrl(router.ROUTE_EXHIBITION_CREATE)}
          />
        </div>
      </TopSearch>
      <ExhibitionsTable goToUrl={goToUrl} />
    </div>
  )
}

const getExhibitions = (state) => {
  const exhibitions = Object.values(state.exhibitions.items)
  return !state.filters.visible ? exhibitions : filterExhibitions(exhibitions, state.filters.q)
}

const filterExhibitions = (items, query) => {
  const lowerQuery = query.toLowerCase()
  return items.filter((e) => e.title && e.title.toLowerCase().includes(lowerQuery))
}

const Exhibitions = () => {
  const loading = useSelector((state) => state.exhibitions.state)
  const error = useSelector((state) => state.exhibitions.error)
  const watch = 'page'
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadExhibitionsBasic())
    dispatch(loadPersons())
  }
  return (
    <DataProviderFunctional
      error={error}
      load={load}
      loading={loading}
      watch={watch}
      component={ExhibitionsPresenter}
    />
  )
}

export default Exhibitions
