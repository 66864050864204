export const getName = (id, persons) => {
  const item = Object.values(persons).find((i) => id === i.id)
  if (!item) {
    return ''
  }
  return (item.firstname ? item.firstname : '') + ' ' + (item.lastname ? item.lastname : '')
}

export const getInstitutionName = (id, institutions) => {
  const item = Object.values(institutions).find((i) => id === i.id)
  if (!item) {
    return ''
  }
  return item.name ? item.name : ''
}

export const getPersonsList = (items) => {
  return items.map((item) => getName(item.id, items)).join(', ')
}

export const getInstitutionsList = (items) => {
  return items.map((item) => getInstitutionName(item.id, items)).join(', ')
}

export const getAuthors = (personsArray, institutionsArray) => {
  const persons = personsArray ? getPersonsList(personsArray) : ''
  const join = personsArray && personsArray.length > 0 && institutionsArray && institutionsArray.length > 0 ? ', ' : ' '
  const institutions = institutionsArray ? getInstitutionsList(institutionsArray) : ''
  return persons + join + institutions
}

export const parseDateCustom = (dateStr) => {
  if (!dateStr) return Infinity
  dateStr = String(dateStr)

  const fullDateMatch = dateStr.match(/^(\d{4})-(\d{2})-(\d{2})$/) // YYYY-MM-DD
  const yearMonthMatch = dateStr.match(/^(\d{4})-(\d{2})$/) // YYYY-MM
  const yearOnlyMatch = dateStr.match(/^(\d{4})$/) // YYYY
  const yearRangeMatch = dateStr.match(/^(\d{4})-(\d{4})$/) // YYYY-YYYY

  if (fullDateMatch) {
    return new Date(dateStr).getTime()
  } else if (yearMonthMatch) {
    return new Date(`${yearMonthMatch[1]}-${yearMonthMatch[2]}-01`).getTime()
  } else if (yearOnlyMatch) {
    return new Date(`${yearOnlyMatch[1]}-01-01`).getTime()
  } else if (yearRangeMatch) {
    return new Date(`${yearRangeMatch[1]}-01-01`).getTime()
  }

  const yearMatch = dateStr.match(/\b(\d{4})\b/)
  if (yearMatch) {
    return new Date(`${yearMatch[1]}-01-01`).getTime()
  }

  if (dateStr.match(/ukjent|u\.å\./i)) {
    return Infinity
  }

  return Infinity
}

export const sortDocsByTextOrDate = (items, field, order) => {
  const x = order === 'asc' ? 1 : -1
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

  return items.slice().sort((a, b) => {
    let valueA = a[field] || ''
    let valueB = b[field] || ''

    // Ensure empty values go last
    if (!valueA && valueB) return 1
    if (!valueB && valueA) return -1
    if (!valueA && !valueB) return 0

    // Special case: sorting dates based on the first year
    if (field === 'date') {
      const yearA = parseDateCustom(valueA)
      const yearB = parseDateCustom(valueB)

      if (yearA !== null && yearB !== null) return (yearA - yearB) * x
      if (yearA !== null) return -1 * x // Move valid years above nulls
      if (yearB !== null) return 1 * x
      return 0
    }

    // Special case: sorting derived fields (type, subtype, authors)
    if (field === 'type' || field === 'subtype' || field === 'author' || field === 'recipient') {
      return collator.compare(valueA.toLowerCase(), valueB.toLowerCase()) * x
    }

    // Default text sorting
    return collator.compare(valueA, valueB) * x
  })
}
