import React, { useCallback, useEffect, useMemo } from 'react'
import { push } from 'connected-react-router'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import * as router from '../../../services/router'
import { institutionsOrder, loadInstitutions } from '../../../actions/institutionsActions'
import { TopSearch } from '../../header/TopSearch'
import { formatDate, sortByTextOrDate } from '../../../utils/utils'
import Filters from './Filters'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import { Add } from '@mui/icons-material'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import { useDispatch, useSelector } from 'react-redux'
import TextButton from '../../general/TextButton'
import { useTheme } from '@mui/material'
import { filtersReset } from '../../../actions/filtersActions'
import { useRxDebounce } from '../../../utils/hooks'

const headers = [
  { field: 'name', title: 'Name' },
  { field: 'founded', title: 'Founded' },
  { field: 'closed', title: 'Closing Date' },
  { field: 'updated_at', title: 'Last Edited' },
]

const ListTableHead = makeSortableTableHeader(headers)

const searchInstitution = (institution, searchQuery) => {
  if (!searchQuery) return true
  return institution.name.toLowerCase().includes(searchQuery.toLowerCase())
}

const TableRows = ({ items, goToUrl, orderBy, order }) => {
  const sortedItems = useMemo(() => sortByTextOrDate(items, orderBy, order), [items, orderBy, order])

  const handleRowClick = useCallback(
    (id) => {
      goToUrl(router.ROUTE_INSTITUTION_EDIT + id)
    },
    [goToUrl]
  )

  return sortedItems.map((item) => (
    <TableRow key={item.id} onClick={() => handleRowClick(item.id)}>
      <CustomTableCell>{`${item.name}`}</CustomTableCell>
      <CustomTableCell>{formatDate(item.founded)}</CustomTableCell>
      <CustomTableCell>{formatDate(item.closed)}</CustomTableCell>
      <CustomTableCell>{formatDate(item.updated_at)}</CustomTableCell>
    </TableRow>
  ))
}

const getFilteredInstitutions = (state) => {
  const institutions = Object.values(state.institutions.items)
  if (!state.filters.visible) return institutions

  return institutions.filter((institution) => searchInstitution(institution, state.filters.q))
}

const InstitutionsTable = React.memo(({ goToUrl }) => {
  const { order, orderBy } = useSelector((state) => getOrder(state, 'institutions'))
  const institutions = useSelector(getFilteredInstitutions)
  const dispatch = useDispatch()
  const orderFn = useRxDebounce((field) => {
    const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc'
    dispatch(institutionsOrder(field, newOrder))
  }, 200)

  return (
    <Table className="non-fixed">
      <ListTableHead orderBy={orderBy} order={order} orderFn={orderFn} />
      <TableBody>
        <TableRows orderBy={orderBy} order={order} items={institutions} goToUrl={goToUrl} />
      </TableBody>
    </Table>
  )
})

const InstitutionsPresenter = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const goToUrl = useCallback((url) => dispatch(push(url)), [dispatch])

  useEffect(() => {
    dispatch(filtersReset())
  }, [dispatch])

  return (
    <div className="exhibitions">
      <TopSearch title="Institutions" FiltersComponent={Filters}>
        <div className="item-right">
          <TextButton
            label="Add institution"
            color="primary"
            startIcon={<Add style={{ color: theme.palette.primary.main }} />}
            onClick={() => goToUrl(router.ROUTE_INSTITUTION_CREATE)}
          />
        </div>
      </TopSearch>
      <InstitutionsTable goToUrl={goToUrl} />
    </div>
  )
}

const Institutions = () => {
  const loading = useSelector((state) => state.institutions.state)
  const error = useSelector((state) => state.institutions.error)
  const watch = 'page'
  const dispatch = useDispatch()
  const load = () => dispatch(loadInstitutions())

  return (
    <DataProviderFunctional
      error={error}
      load={load}
      loading={loading}
      watch={watch}
      component={InstitutionsPresenter}
    />
  )
}

export default Institutions
