import { useCallback, useEffect, useMemo } from 'react'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

export const useRxDebounce = (callback, delay) => {
  const subject = useMemo(() => new Subject(), [])

  useEffect(() => {
    const subscription = subject.pipe(debounceTime(delay)).subscribe(callback)

    // Automatic cleanup on unmount
    return () => subscription.unsubscribe()
  }, [callback, delay, subject])

  return useCallback((value) => subject.next(value), [subject])
}
