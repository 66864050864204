import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './TopSearch.css'
import { IconButton } from '@mui/material'
import { filterVisibilityToggle } from '../../actions/filtersActions'
import { Close, Search } from '@mui/icons-material'
import { COLOR_LIGHT_TEXT } from '../../config/colors'

export const ToggleSearchButton = ({ visible }) => {
  const dispatch = useDispatch()

  const handleToggleFilter = () => {
    dispatch(filterVisibilityToggle())
  }

  return (
    <div className="search-section">
      <IconButton onClick={() => handleToggleFilter()} style={{ color: COLOR_LIGHT_TEXT }} size="large">
        {!visible ? <Search /> : <Close />}
      </IconButton>
    </div>
  )
}

const Title = ({ title, colorAccent }) => (
  <div className="title" style={{ color: colorAccent }}>
    <h3 className="title-heading">{title}</h3>
  </div>
)

export const TopSearch = ({ title, FiltersComponent, resultsRoute, resultsLoader, children, hideSearch = false }) => {
  const visible = useSelector((state) => state.filters.visible)
  const colorAccent = useSelector((state) => state.auth.colorAccent)
  return (
    <div className="search">
      <div className="left-right-block flex-box-centered">
        <Title title={title} colorAccent={colorAccent} />
        {children}
        {!hideSearch && <ToggleSearchButton visible={visible} />}
      </div>
      <FiltersComponent resultsRoute={resultsRoute} resultsLoader={resultsLoader} />
    </div>
  )
}
