import React, { useCallback, useEffect, useMemo } from 'react'
import * as router from '../../../services/router'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { loadDocumentsAll, documentsOrder } from '../../../actions/documentsActions'
import { TopSearch } from '../../header/TopSearch'
import { formatDate, getTitleByValue, getTitleByValueInObject } from '../../../utils/utils'
import Filters from './Filters'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import { Add } from '@mui/icons-material'
import { DOCUMENT_SUBTYPES, DOCUMENT_TYPES } from '../../../config/config'
import { loadProvenancePersons } from '../../../actions/provenancePersonsActions'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import TextButton from '../../general/TextButton'
import { useTheme } from '@mui/material'
import { filtersReset } from '../../../actions/filtersActions'
import { getAuthors, parseDateCustom, sortDocsByTextOrDate } from './utils'
import { useRxDebounce } from '../../../utils/hooks'

const headers = [
  { field: 'title', title: 'Title' },
  { field: 'author', title: 'Author' },
  { field: 'recipient', title: 'Recipient' },
  { field: 'date', title: 'Date' },
  { field: 'type', title: 'Type' },
  { field: 'subtype', title: 'Subtype' },
  { field: 'accession_number', title: 'Accession Number' },
  { field: 'updated_at', title: 'Last Edited' },
]

const ListTableHead = makeSortableTableHeader(headers)

const searchDocument = (doc, searchQuery) => {
  const searchable = [
    doc.title?.toLowerCase() || '',
    doc.subtype?.toLowerCase() || '',
    typeof doc.date === 'string' || typeof doc.date === 'number' ? String(doc.date).toLowerCase() : '',
    ...(doc.documents_recipients || []).map((r) => `${r.firstname.toLowerCase()} ${r.lastname.toLowerCase()}`),
    ...(doc.documents_authors || []).map((a) => `${a.firstname.toLowerCase()} ${a.lastname.toLowerCase()}`),
  ].join(' ')
  return searchable.includes(searchQuery.toLowerCase())
}

const TableRows = ({ items, goToUrl, orderBy, order }) => {
  const sortedItems = useMemo(() => {
    return sortDocsByTextOrDate(
      items.map((item) => {
        return {
          ...item,
          author: getAuthors(item.documents_authors, item.documents_authors_institutions),
          recipient: getAuthors(item.documents_recipients, item.documents_recipients_institutions),
          type: item.type ? getTitleByValue(item.type, DOCUMENT_TYPES) : '',
          subtype: item.subtype ? getTitleByValueInObject(item.subtype, DOCUMENT_SUBTYPES) : '',
        }
      }),
      orderBy,
      order
    )
  }, [items, orderBy, order])

  const handleRowClick = useCallback(
    (id) => {
      goToUrl(router.ROUTE_DOCUMENT_EDIT + id)
    },
    [goToUrl]
  )
  return sortedItems.map((item) => (
    <TableRow key={item.id} onClick={() => handleRowClick(item.id)}>
      <CustomTableCell>{item.title || ''}</CustomTableCell>
      <CustomTableCell>{item.author}</CustomTableCell>
      <CustomTableCell>{item.recipient}</CustomTableCell>
      <CustomTableCell>{item.date}</CustomTableCell>
      <CustomTableCell>{item.type}</CustomTableCell>
      <CustomTableCell>{item.subtype}</CustomTableCell>
      <CustomTableCell>{item.accession_number || ''}</CustomTableCell>
      <CustomTableCell>{item.updated_at ? formatDate(item.updated_at) : ''}</CustomTableCell>
    </TableRow>
  ))
}

const DocumentsTable = React.memo(({ goToUrl }) => {
  const { order, orderBy } = useSelector((state) => getOrder(state, 'documents'))
  const searchQuery = useSelector((state) => state.filters.q)
  const items = useSelector((state) => state.documents.items)
  const itemList = useMemo(() => Object.values(items), [items])
  const dispatch = useDispatch()

  const orderFn = useRxDebounce((field) => {
    const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc'
    dispatch(documentsOrder(field, newOrder))
  }, 200)

  const filteredItems = useMemo(() => {
    if (!searchQuery) return itemList
    return itemList.filter((doc) => searchDocument(doc, searchQuery))
  }, [itemList, searchQuery])

  return (
    <Table className="non-fixed">
      <ListTableHead orderBy={orderBy} order={order} orderFn={orderFn} />
      <TableBody>
        <TableRows orderBy={orderBy} order={order} items={filteredItems} goToUrl={goToUrl} />
      </TableBody>
    </Table>
  )
})

const DocumentsPresenter = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const goToUrl = useCallback((url) => dispatch(push(url)), [dispatch])

  useEffect(() => {
    dispatch(filtersReset())
  }, [dispatch])

  return (
    <div className="exhibitions">
      <TopSearch title="Documents" FiltersComponent={Filters}>
        <div className="item-right">
          <TextButton
            label="Add document"
            color="primary"
            startIcon={<Add style={{ color: theme.palette.primary.main }} />}
            onClick={() => goToUrl(router.ROUTE_DOCUMENT_CREATE)}
          />
        </div>
      </TopSearch>
      <DocumentsTable goToUrl={goToUrl} />
    </div>
  )
}

const Documents = () => {
  const loading = useSelector((state) => state.documents.state)
  const error = useSelector((state) => state.documents.error)
  const watch = 'page'
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadDocumentsAll())
    dispatch(loadProvenancePersons())
  }
  return (
    <DataProviderFunctional error={error} load={load} loading={loading} watch={watch} component={DocumentsPresenter} />
  )
}

export default Documents
