import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useTheme } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { Add } from '@mui/icons-material'
import * as router from '../../../services/router'
import { loadPersons, personsOrder } from '../../../actions/personsActions'
import { TopSearch } from '../../header/TopSearch'
import { dateByAccuracy, formatDate, sortByTextOrDate } from '../../../utils/utils'
import Filters from './Filters'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import TextButton from '../../general/TextButton'
import { useRxDebounce } from '../../../utils/hooks'
import { filtersReset } from '../../../actions/filtersActions'

const headers = [
  { field: 'lastname', title: 'Last Name' },
  { field: 'firstname', title: 'First Name' },
  { field: 'birth_year', title: 'Born' },
  { field: 'death_year', title: 'Death' },
  { field: 'updated_at', title: 'Last Edited' },
]

const ListTableHead = makeSortableTableHeader(headers)

const searchPerson = (person, searchQuery) => {
  const searchable = [
    person.firstname.toLowerCase(),
    person.lastname.toLowerCase(),
    ...(person.alternative_names || []).map((item) => item.name.toLowerCase()),
  ].join(' ')
  return searchable.includes(searchQuery.toLowerCase())
}

const TableRows = React.memo(({ goToUrl, orderBy, order, filteredItems }) => {
  const sortedItems = useMemo(() => sortByTextOrDate(filteredItems, orderBy, order), [filteredItems, orderBy, order])

  const handleRowClick = useCallback(
    (id) => {
      goToUrl(router.ROUTE_PERSON_EDIT + id)
    },
    [goToUrl]
  )

  return sortedItems.map((item) => (
    <TableRow key={item.id} onClick={() => handleRowClick(item.id)}>
      <CustomTableCell>{item.lastname}</CustomTableCell>
      <CustomTableCell>{item.firstname}</CustomTableCell>
      <CustomTableCell>
        {dateByAccuracy(item.accuracy_birth, item.birth_year, item.birth_month, item.birth_day)}
      </CustomTableCell>
      <CustomTableCell>
        {dateByAccuracy(item.accuracy_death, item.death_year, item.death_month, item.death_day)}
      </CustomTableCell>
      <CustomTableCell>{formatDate(item.updated_at)}</CustomTableCell>
    </TableRow>
  ))
})

const PersonsTable = React.memo(({ goToUrl }) => {
  const { order, orderBy } = useSelector((state) => getOrder(state, 'persons'))
  const items = useSelector((state) => state.persons.items)
  const itemList = useMemo(() => Object.values(items), [items])
  const searchQuery = useSelector((state) => state.filters.q)
  const dispatch = useDispatch()
  const orderFn = useRxDebounce((field) => {
    const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc'
    dispatch(personsOrder(field, newOrder))
  }, 200)

  const filteredItems = useMemo(() => {
    if (!searchQuery) return itemList
    return itemList.filter((person) => searchPerson(person, searchQuery))
  }, [itemList, searchQuery])

  return (
    <Table className="non-fixed">
      <ListTableHead orderBy={orderBy} order={order} orderFn={orderFn} />
      <TableBody>
        <TableRows orderBy={orderBy} order={order} goToUrl={goToUrl} filteredItems={filteredItems} />
      </TableBody>
    </Table>
  )
})

const PersonsPresenter = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const goToUrl = useCallback((url) => dispatch(push(url)), [dispatch])

  useEffect(() => {
    dispatch(filtersReset())
  }, [dispatch])

  return (
    <div className="exhibitions">
      <TopSearch title="People" FiltersComponent={Filters}>
        <div className="item-right">
          <TextButton
            label="Add person"
            onClick={() => goToUrl(router.ROUTE_PERSON_CREATE)}
            color="primary"
            startIcon={<Add style={{ color: theme.palette.primary.main }} />}
          />
        </div>
      </TopSearch>
      <PersonsTable goToUrl={goToUrl} />
    </div>
  )
}

const Persons = () => {
  const error = useSelector((state) => state.persons.error)
  const loading = useSelector((state) => state.persons.state)
  const watch = 'page'
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadPersons())
  }
  return (
    <DataProviderFunctional error={error} load={load} loading={loading} watch={watch} component={PersonsPresenter} />
  )
}

export default Persons
