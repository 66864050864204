import React, { useCallback, useEffect, useMemo } from 'react'
import * as router from '../../../services/router'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Add } from '@mui/icons-material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { loadKeywords, keywordsOrder } from '../../../actions/keywordsActions'
import { TopSearch } from '../../header/TopSearch'
import { formatDate, sortByTextOrDate } from '../../../utils/utils'
import Filters from './Filters'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import { KEYWORDS_CATEGORIES, KEYWORDS_SUBCATEGORIES } from '../../../config/config'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import TextButton from '../../general/TextButton'
import { useTheme } from '@mui/material'
import { filtersReset } from '../../../actions/filtersActions'

const headers = [
  { field: 'keyword', title: 'Keyword' },
  { field: 'subcategory', title: 'Subcategory' },
  { field: 'category', title: 'Category' },
  { field: 'updated_at', title: 'Last Edited' },
]

const ListTableHead = makeSortableTableHeader(headers)

const TableRows = ({ items, goToUrl, orderBy, order }) => {
  const sortedItems = useMemo(() => sortByTextOrDate(items, orderBy, order), [items, orderBy, order])

  return sortedItems.map((item) => (
    <TableRow key={item.id} onClick={() => goToUrl(router.ROUTE_KEYWORD_EDIT + item.id)}>
      <CustomTableCell>{`${item.keyword}`}</CustomTableCell>
      <CustomTableCell>{`${KEYWORDS_SUBCATEGORIES[item.category].find((i) => i.value === item.subcategory).title
        }`}</CustomTableCell>
      <CustomTableCell>{`${KEYWORDS_CATEGORIES.find((i) => i.value === item.category).title}`}</CustomTableCell>
      <CustomTableCell>{formatDate(item.updated_at)}</CustomTableCell>
    </TableRow>
  ))
}

const KeywordsTable = React.memo(({ goToUrl }) => {
  const { order, orderBy } = useSelector((state) => getOrder(state, 'keywords'))
  const items = useSelector((state) => state.keywords.items)
  const searchQuery = useSelector((state) => state.filters.q)
  const visible = useSelector((state) => state.filters.visible)
  const dispatch = useDispatch()

  const orderFn = (field) => {
    const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc'
    dispatch(keywordsOrder(field, newOrder))
  }

  const filteredItems = useMemo(() => {
    const keywordList = Object.values(items)

    if (!visible) {
      return keywordList
    } else {
      return keywordList.filter((k) => {
        const searchable = `${k.keyword.toLowerCase()}`
        return searchable.includes(searchQuery.toLowerCase())
      })
    }
  }, [items, searchQuery, visible])

  return (
    <Table className="non-fixed">
      <ListTableHead orderBy={orderBy} order={order} orderFn={orderFn} />
      <TableBody>
        <TableRows orderBy={orderBy} order={order} items={filteredItems} goToUrl={goToUrl} />
      </TableBody>
    </Table>
  )
})

const KeywordsPresenter = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const goToUrl = useCallback((url) => dispatch(push(url)), [dispatch])

  useEffect(() => {
    dispatch(filtersReset())
  }, [dispatch])

  return (
    <div className="exhibitions">
      <TopSearch title="Keywords" FiltersComponent={Filters}>
        <div className="item-right">
          <TextButton
            label="Add keyword"
            color="primary"
            startIcon={<Add style={{ color: theme.palette.primary.main }} />}
            onClick={() => goToUrl(router.ROUTE_KEYWORD_CREATE)}
          />
        </div>
      </TopSearch>
      <KeywordsTable goToUrl={goToUrl} />
    </div>
  )
}

const Keywords = () => {
  const loading = useSelector((state) => state.keywords.state)
  const error = useSelector((state) => state.keywords.error)
  const watch = 'page'
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadKeywords())
  }

  return (
    <DataProviderFunctional error={error} load={load} loading={loading} watch={watch} component={KeywordsPresenter} />
  )
}

export default Keywords
