import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { push } from 'connected-react-router'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Pagination from '../pagination/Pagination'
import { Top } from '../header/Top'
import { Add } from '@mui/icons-material'
import { getOrder } from '../../reducers/crud'
import { sortByTextOrDate } from '../../utils/utils'
import DataProviderFunctional from '../loader/DataProviderFunctional'
import TextButton from './TextButton'
import { useTheme } from '@mui/material'

export const makeList = (options) => {

  const ListTable = React.memo(() => {
    const HeaderComponent = options.renderHeader
    const RowComponent = options.renderRow
    let { page } = useParams()
    const getPage = () => page ?? 1
    const items = useSelector((state) => state[options.model]?.pages[getPage()] || [], shallowEqual)
    const { order, orderBy } = useSelector((state) => getOrder(state, options.model), shallowEqual)
    const dispatch = useDispatch()

    const orderedItems = React.useMemo(
      () => (items.length ? sortByTextOrDate(items, orderBy, order) : []),
      [items, orderBy, order]
    )

    const handleItemClick = React.useCallback(
      (item) => {
        if (item) dispatch(push(`${options.editRoute}${item.id}`))
      },
      [dispatch, options.editRoute]
    )

    const orderFn = React.useCallback(
      (field) => {
        const newOrder = orderBy === field && order === 'asc' ? 'desc' : 'asc'
        dispatch(options.orderFn(field, newOrder))
      },
      [dispatch, options.orderFn, orderBy, order]
    )

    return (
      <Table className="non-fixed">
        <HeaderComponent order={order} orderBy={orderBy} orderFn={orderFn} />
        <TableBody>
          {orderedItems.map((item, i) => (
            <RowComponent key={i} item={item} onClick={() => handleItemClick(item)} />
          ))}
        </TableBody>
      </Table>
    )
  })

  const ListPresentation = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    // const currentPage = useSelector((state) => state[options.model].page, shallowEqual)
    // const total = useSelector((state) => state[options.model].total, shallowEqual)
    const onCreateClick = React.useCallback(() => {
      dispatch(push(options.createRoute))
    }, [dispatch, options.createRoute])

    return (
      <div>
        <Top title={options.title}>
          <div className="item-right">
            <TextButton
              label="Add"
              color="primary"
              startIcon={<Add style={{ color: theme.palette.primary.main }} />}
              onClick={onCreateClick}
            />
          </div>
        </Top>
        <ListTable />
        {/* <Pagination currentPage={currentPage} total={total} route={options.paginationRoute} /> */}
      </div>
    )
  }

  const List = () => {
    const loading = useSelector((state) => state[options.model].state)
    const error = useSelector((state) => state[options.model].error)
    const watch = 'page'
    const dispatch = useDispatch()
    let { page } = useParams()
    const getPage = () => (page === undefined ? 1 : page)
    const load = (props) => {
      if (Array.isArray(options.loadFn)) {
        options.loadFn.forEach((fn) => dispatch(fn(getPage(props))))
      } else {
        dispatch(options.loadFn(getPage(props)))
      }
    }

    return (
      <DataProviderFunctional error={error} load={load} loading={loading} watch={watch} component={ListPresentation} />
    )
  }
  return List
}
