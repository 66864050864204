import { combineEpics } from 'redux-observable'
import { loadArtworksEpic, searchArtworkEpic, loadArtworksAllEpic } from './artworksActions'
import { authInitEpic, loginEpic, logoutEpic } from './authActions'
import { createArtworkEpic, deleteArtworkEpic, loadArtworkEpic, updateArtworkEpic } from './artworkActions'
import { loadUsersEpic } from './usersActions'
import { createCommentsEpic, loadCommentsEpic } from './commentsActions'
import { loadHistoryEpic, loadUserHistoryEpic } from './historyActions'
import { createUserEpic, deleteUserEpic, loadUserEpic, updateUserEpic } from './userActions'
import {
  createReferenceEpic,
  loadReferenceEpic,
  loadReferencesEpic,
  updateReferenceEpic,
  searchReferencesEpic,
  deleteReferenceEpic,
} from './referencesActions'
import {
  createExhibitionEpic,
  loadExhibitionEpic,
  loadExhibitionsEpic,
  loadExhibitionsBasicEpic,
  updateExhibitionEpic,
  deleteExhibitionEpic,
} from './exhibitionsActions'
import { createPersonEpic, loadPersonEpic, loadPersonsEpic, updatePersonEpic, deletePersonEpic } from './personsActions'
import {
  createKeywordEpic,
  loadKeywordEpic,
  loadKeywordsEpic,
  updateKeywordEpic,
  deleteKeywordEpic,
} from './keywordsActions'
import { loadProfessionsEpic } from './professionsActions'
import { loadCountriesEpic } from './countriesActions'
import {
  createInstitutionEpic,
  deleteInstitutionEpic,
  loadInstitutionEpic,
  loadInstitutionsEpic,
  updateInstitutionEpic,
} from './institutionsActions'
import { loadProvenancePersonsEpic } from './provenancePersonsActions'
import { loadProvenanceInstitutionsEpic } from './provenanceInstitutionsActions'
import {
  createDocumentEpic,
  deleteDocumentEpic,
  deleteDocumentPageEpic,
  loadDocumentEpic,
  loadDocumentsEpic,
  updateDocumentEpic,
  searchDocumentsEpic
} from './documentsActions'
import {
  createCatalogueEpic,
  loadCatalogueEpic,
  loadCataloguesEpic,
  updateCatalogueEpic,
  deleteCatalogueEpic,
} from './cataloguesActions'
import { forgotPasswordEpic } from './forgotPasswordActions'
import { resetPasswordEpic } from './resetPasswordActions'

export const appEpic = combineEpics(
  authInitEpic,
  loginEpic,
  logoutEpic,

  loadArtworksEpic,
  loadArtworksAllEpic,
  searchArtworkEpic,
  loadArtworkEpic,
  updateArtworkEpic,
  createArtworkEpic,
  deleteArtworkEpic,

  loadCommentsEpic,
  createCommentsEpic,

  loadReferencesEpic,
  searchReferencesEpic,
  loadReferenceEpic,
  updateReferenceEpic,
  createReferenceEpic,
  deleteReferenceEpic,

  loadPersonsEpic,
  loadPersonEpic,
  updatePersonEpic,
  createPersonEpic,
  deletePersonEpic,

  loadProvenancePersonsEpic,
  loadProvenanceInstitutionsEpic,

  loadInstitutionsEpic,
  loadInstitutionEpic,
  updateInstitutionEpic,
  createInstitutionEpic,
  deleteInstitutionEpic,

  loadExhibitionsEpic,
  loadExhibitionsBasicEpic,
  loadExhibitionEpic,
  updateExhibitionEpic,
  createExhibitionEpic,
  deleteExhibitionEpic,

  loadHistoryEpic,
  loadUserHistoryEpic,

  loadUsersEpic,
  loadUserEpic,
  updateUserEpic,
  createUserEpic,
  deleteUserEpic,

  loadProfessionsEpic,

  loadCountriesEpic,

  loadKeywordsEpic,
  loadKeywordEpic,
  updateKeywordEpic,
  createKeywordEpic,
  deleteKeywordEpic,

  loadDocumentsEpic,
  loadDocumentEpic,
  updateDocumentEpic,
  createDocumentEpic,
  deleteDocumentEpic,
  deleteDocumentPageEpic,
  searchDocumentsEpic,

  loadCataloguesEpic,
  loadCatalogueEpic,
  updateCatalogueEpic,
  createCatalogueEpic,
  deleteCatalogueEpic,

  forgotPasswordEpic,
  resetPasswordEpic
)
